export default function IconfChat() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9695 8H15.0309C10.6028 8 7.0004 11.5885 7.0004 15.9997C7.0004 17.9711 7.7123 19.8355 9.01457 21.2997L7.1392 23.1863C7.00232 23.3227 6.96267 23.5279 7.03686 23.7063C7.1117 23.884 7.28631 24 7.48012 24H16.9695C21.3977 24 25 20.4108 25 15.9997C25 11.5885 21.3977 8 16.9695 8Z"
                stroke="#010101"
                strokeWidth="1.6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5198 15.1221C10.9677 15.1221 10.5198 15.5698 10.5198 16.1216C10.5198 16.6734 10.9677 17.1211 11.5198 17.1211C12.0718 17.1211 12.5198 16.6734 12.5198 16.1216C12.5198 15.5698 12.0718 15.1221 11.5198 15.1221Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1211 15.1221C15.569 15.1221 15.1211 15.5698 15.1211 16.1216C15.1211 16.6734 15.569 17.1211 16.1211 17.1211C16.6732 17.1211 17.1211 16.6734 17.1211 16.1216C17.1211 15.5698 16.6732 15.1221 16.1211 15.1221Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7231 15.1221C20.1711 15.1221 19.7231 15.5698 19.7231 16.1216C19.7231 16.6734 20.1711 17.1211 20.7231 17.1211C21.2752 17.1211 21.7231 16.6734 21.7231 16.1216C21.7231 15.5698 21.2752 15.1221 20.7231 15.1221Z"
                fill="black"
            />
        </svg>
    );
}
